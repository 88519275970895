export function initSlider() {
    const sliders = document.querySelectorAll("[vdx-slider-slider]");
    sliders.forEach(slider => {
        // Early returns for invalid configurations
        if (slider.dataset.initialized) return; // Prevent re-initialization
        slider.dataset.initialized = true; // Mark as initialized

        const initialSlides = slider.querySelectorAll('[vdx-slider-slide]');
        // Add index number as value to each slide
        initialSlides.forEach((slide, index) => {
            slide.setAttribute('vdx-slider-slide', index.toString());
        });
        if (initialSlides.length === 0) {
            console.log('No slides found');
            return;
        }
        
        const prevButton = slider.querySelector('[vdx-slider-prev]');
        if (!prevButton) {
            console.log('No prev button found');
            return;
        }
        
        const nextButton = slider.querySelector('[vdx-slider-next]');
        if (!nextButton) {
            console.log('No next button found');
            return;
        }
        
        // Configuration variables
        const standardSlider = slider.getAttribute('vdx-slider-slider') === '' || slider.getAttribute('vdx-slider-slider') === 'standard';
        const infiniteSlider = slider.getAttribute('vdx-slider-slider') === 'infinite';
        const autoRotate = slider.hasAttribute('vdx-slider-autorotate') ? slider : null;
        const autoRotateTime = slider.getAttribute('vdx-slider-autorotate') || 3000; // Default auto rotate time is 3000ms
        let initialSlideIndex = 0;
        
        // Handle slider setup
        function setupStandardSlider() {
            // Set initial button states for standard slider
            prevButton.classList.add('vdx--inactive');
            prevButton.setAttribute('aria-disabled', 'true');
            if (initialSlides.length === 1) {
                nextButton.setAttribute('aria-disabled', 'true');
                nextButton.classList.add('vdx--inactive');
            } else {
                nextButton.setAttribute('aria-disabled', 'false');
            }
        }

        function setupInfiniteSlider() {
            // Clone each slide and append to the end for infinite scrolling
            const slideContainer = slider.querySelector('[vdx-slider-slide]').parentElement;
            // First set of clones
            // Create multiple sets of clones for infinite scrolling
            const cloneTypes = ['primary', 'secondary'];
            
            cloneTypes.forEach(cloneType => {
                initialSlides.forEach(slide => {
                    const clonedSlide = slide.cloneNode(true);
                    clonedSlide.setAttribute('vdx-slider-clone', cloneType);
                    slideContainer.appendChild(clonedSlide);
                });
            });
            
            
            
            // Set initial button states for infinite slider
            prevButton.setAttribute('aria-disabled', 'false');
            nextButton.setAttribute('aria-disabled', 'false');
        }

        // Call the appropriate setup function based on slider type
        if (infiniteSlider) {
            setupInfiniteSlider();
        } else {
            setupStandardSlider();
        }
        // Update slides collection to include the cloned slides
        const slides = slider.querySelectorAll('[vdx-slider-slide]');
        
        // Set initial active slide
        setTimeout(() => {
            slides[initialSlideIndex].classList.add('vdx--active');
        }, 0);
        
        // Add vdx--active class to all children of the initial active slide
        slides[initialSlideIndex].querySelectorAll('*').forEach(child => {
            child.classList.add('vdx--active'); // Add to children
        });
        
        // Calculate slide dimensions
        const flexGapParent = slides[0].parentElement;
        const flexGap = parseInt(getComputedStyle(flexGapParent).gap) || 0;
        
        let slideWidth = slides[0] ? slides[0].offsetWidth + parseInt(getComputedStyle(slides[0]).marginLeft) + parseInt(getComputedStyle(slides[0]).marginRight) + flexGap : 0;
        const slidesCount = slides.length;
        
        // Recalculate slide width on window resize
        function recalculateSlideWidth() {
            const flexGapParent = slides[0].parentElement;
            const flexGap = parseInt(getComputedStyle(flexGapParent).gap) || 0;
            slideWidth = slides[0] ? slides[0].offsetWidth + parseInt(getComputedStyle(slides[0]).marginLeft) + parseInt(getComputedStyle(slides[0]).marginRight) + flexGap : 0;
           
        }
        window.addEventListener('resize', recalculateSlideWidth);
        
        // Core slider movement function
        function moveSlider(index) {
            // Handle special case for infinite slider when moving between last and first slide
            if (infiniteSlider) {
                const primarySlide = slides[initialSlideIndex].getAttribute('vdx-slider-clone') === 'primary';            
                // Get all primary and secondary slides
                const primarySlides = slider.querySelectorAll('[vdx-slider-clone="primary"]');
                const secondarySlides = slider.querySelectorAll('[vdx-slider-clone="secondary"]');
                // Reference important slides
                const firstPrimarySlide = primarySlides[0];
                const secondPrimarySlide = primarySlides[1];
                const lastPrimarySlide = primarySlides[primarySlides.length - 1];
                const firstSecondarySlide = secondarySlides[0];
                const currentSlide = slides[initialSlideIndex];
                // Handle special case when we're at the first secondary slide (clone)
                if (slides[initialSlideIndex] === firstSecondarySlide) {

                    // Move to the first primary slide
                    const firstPrimaryIndex = Array.from(slides).indexOf(secondPrimarySlide);
                    if (firstPrimaryIndex !== -1) {
                        
                        // First, apply the transform to all slides with no transition
                        slides.forEach((slide) => {
                            slide.style.transition = 'none';
                            slide.style.transform = `translateX(${(-firstPrimaryIndex +1) * slideWidth}px)`;
                            slide.classList.remove('vdx--active');
                            slide.querySelectorAll('*').forEach(child => {
                                child.classList.remove('vdx--active');
                            });
                        });
                        
                        // Add vdx--active class to the first primary slide
                        firstPrimarySlide.classList.add('vdx--active');
                        
                        // Force a reflow to ensure the transition: none takes effect
                        void slider.offsetWidth;
                        
                        // Then apply the final transform with transition
                        setTimeout(() => {
                            slides.forEach((slide, i) => {
                                slide.style.transition = '';
                                slide.style.transform = `translateX(${(-firstPrimaryIndex) * slideWidth}px)`;
                                slide.classList.remove('vdx--active');
                                slide.querySelectorAll('*').forEach(child => {
                                    child.classList.remove('vdx--active');
                                });
                                if (i === firstPrimaryIndex) {
                                    slide.classList.add('vdx--active');
                                    slide.querySelectorAll('*').forEach(child => {
                                        child.classList.add('vdx--active');
                                    });
                                }
                            });
                        }, 0);
                        
                        // Update indices and log for debugging
                        initialSlideIndex = firstPrimaryIndex;
                        index = initialSlideIndex;
                    }
                } else {
                    // Normal slide movement for infinite slider
                    slides.forEach((slide, i) => {
                       
                        slide.style.transition = '';
                        slide.style.transform = `translateX(${(- index) * slideWidth}px)`;
                        slide.classList.remove('vdx--active');
                        slide.querySelectorAll('*').forEach(child => {
                            child.classList.remove('vdx--active');
                        });
                        if (i === index) {
                            slide.classList.add('vdx--active');
                            slide.querySelectorAll('*').forEach(child => {
                                child.classList.add('vdx--active');
                            });
                        }
                    });
                }
            } else {
                // Standard slider movement
                slides.forEach((slide, i) => {
                    slide.style.transition = '';
                    slide.style.transform = `translateX(${(- index) * slideWidth}px)`;
                    slide.classList.remove('vdx--active');
                    slide.querySelectorAll('*').forEach(child => {
                        child.classList.remove('vdx--active');
                    });
                    if (i === index) {
                        slide.classList.add('vdx--active');
                        slide.querySelectorAll('*').forEach(child => {
                            child.classList.add('vdx--active');
                        });
                    }
                });
            }
            
            // Update button states
            prevButton.classList[index === 0 ? 'add' : 'remove']('vdx--inactive');
            prevButton.setAttribute('aria-disabled', index === 0 ? 'true' : 'false');
            
            nextButton.setAttribute('aria-disabled', index === slidesCount - 1 ? 'true' : 'false');
            nextButton.classList[index === slidesCount - 1 ? 'add' : 'remove']('vdx--inactive');
            
            // Update the initial slide index
            initialSlideIndex = index;
            
            // Update navigation dots
            slider.querySelectorAll('[vdx-slider-dot]').forEach((dot, i) => {
                dot.classList.remove('vdx--active');
                if (i === parseInt(slides[index].getAttribute('vdx-slider-slide'))) dot.classList.add('vdx--active');
            });
        }

        // Event handler functions
        function handleButtonClick(event) {
            const button = event.currentTarget;
            moveSlider(button === prevButton ? (initialSlideIndex - 1 + slidesCount) % slidesCount : (initialSlideIndex + 1) % slidesCount);
        }
        
        let touchStartX = 0;
        
        function handleTouchStart(event) {
            touchStartX = event.touches[0].clientX;
        }
        
        function handleTouchMove(event) {
            if (!touchStartX) {
                return;
            }
            
            const touchEndX = event.changedTouches[0].clientX;
            const distance = touchStartX - touchEndX;
            
            // Prevent scrolling the page while swiping
            if (Math.abs(distance) > 10) {
                event.preventDefault();
            }
            
            if (distance > 50) {
                // Swipe left - go to next slide
                moveSlider((initialSlideIndex + 1) % slidesCount);
                touchStartX = 0; // Reset to prevent multiple triggers
            } else if (distance < -50) {
                // Swipe right - go to previous slide
                moveSlider((initialSlideIndex - 1 + slidesCount) % slidesCount);
                touchStartX = 0; // Reset to prevent multiple triggers
            }
        }
        
        function handleTouchEnd() {
            touchStartX = 0; // Reset when touch ends
        }
        
        // Wheel event handling for horizontal scrolling
        let isScrolling = false;
        function handleWheelEvent(event) {
            if (event.deltaY === 0 && Math.abs(event.deltaX) > 10) { // Check if the scroll is horizontal and adjust for both directions
                if (isScrolling) return; // Prevent further execution if already scrolling
                isScrolling = true; // Set the flag to indicate scrolling is in progress
                event.preventDefault(); // Prevent the default scroll behavior
                const scrollDirection = event.deltaX > 0 ? 'next' : 'prev'; // Determine the scroll direction
                let newIndex = initialSlideIndex;
                if (scrollDirection === 'next' && initialSlideIndex !== slidesCount - 1) {
                    newIndex = (initialSlideIndex + 1) % slidesCount;
                } 
                if (scrollDirection === 'prev' && initialSlideIndex !== 0) {
                    newIndex = (initialSlideIndex - 1 + slidesCount) % slidesCount;
                }
                moveSlider(newIndex);
                // Reset the flag after a short delay
                setTimeout(() => {
                    isScrolling = false; // Allow scrolling again after the timeout
                }, 200); // Adjust the timeout duration as needed
            }
        }
        
        // Set up event listeners
        // Button click events
        prevButton.addEventListener('click', (event) => {
            if (prevButton.getAttribute('aria-disabled') === 'false') {
                handleButtonClick(event);
            }
        });
        
        nextButton.addEventListener('click', (event) => {
            if (nextButton.getAttribute('aria-disabled') === 'false') {
                handleButtonClick(event);
            }
        });
        
        // Touch events for mobile
        prevButton.addEventListener('touchend', (event) => {
            if (prevButton.getAttribute('aria-disabled') === 'false') {
                handleButtonClick(event);
            }
        });
        
        nextButton.addEventListener('touchend', (event) => {
            if (nextButton.getAttribute('aria-disabled') === 'false') {
                handleButtonClick(event);
            }
        });
        
        slider.addEventListener('touchstart', (event) => {
            event.preventDefault();
            slider.addEventListener('touchmove', handleTouchMove);
        });
        
        slider.addEventListener('touchend', () => {
            slider.removeEventListener('touchmove', handleTouchMove);
        });
        
        // Wheel event for trackpad support
        slider.addEventListener('wheel', handleWheelEvent);
        
        // Set up navigation dots
        let dotTemplate = slider.querySelector('[vdx-slider-dot]');
        let navigationContainer = slider.querySelector('[vdx-slider-nav]');
        if (navigationContainer) {
            navigationContainer.innerHTML = '';
            initialSlides.forEach((slide, index) => {
                const dot = dotTemplate.cloneNode(true);
                dot.setAttribute('data-slide-index', index);
                navigationContainer.appendChild(dot);
                dot.addEventListener('click', () => moveSlider(index));
                
            });
            let initialDot = navigationContainer.querySelector(`[data-slide-index="0"]`);
            initialDot.classList.add('vdx--active');
        }
        
        // Auto-rotation setup
        if (autoRotate) {
            let autoRotateInterval = setInterval(() => moveSlider((initialSlideIndex + 1) % slidesCount), autoRotateTime);
            slider.addEventListener('mouseover', () => clearInterval(autoRotateInterval));
            slider.addEventListener('click', () => clearInterval(autoRotateInterval));
            slider.addEventListener('mouseout', () => autoRotateInterval = setInterval(() => moveSlider((initialSlideIndex + 1) % slidesCount), autoRotateTime));
        }
    });
}
