export const onClick = () => {
    const executeCode = (code, event, element) => {
        try {
            new Function(code)();
        } catch (error) {
            displayClickError(element, error);
        }
    };

    const displayClickError = (element, error) => {
        console.error("Error executing vdx-on-click code:", error);
        element.style.border = "1px red dotted";
        element.setAttribute("title", error.message);
        element.style.cursor = "help";
    };

    document.querySelectorAll("[vdx-on-click]").forEach(element => {
        element.addEventListener("click", event => {
            const code = element.getAttribute("vdx-on-click");
            if (typeof window[code] === "function") {
                try {
                    window[code](event, element);
                } catch (error) {
                    displayClickError(element, error);
                }
            } else {
                executeCode(code, event, element);
            }
        });
    });
};
