export const filterItems = () => {
    // Check if the function has already been initialized to prevent double execution
    if (window.vdxFiltersInitialized) return;
    window.vdxFiltersInitialized = true;
    
    const form = document.querySelectorAll("[vdx-filters-filters]");
    if (form.length === 0) return;
    
    const listItems = document.querySelectorAll("[vdx-filters-item]");
    if (listItems.length === 0) return;
    
    const formInputs = form[0].querySelectorAll('input');
    const checkboxes = Array.from(formInputs).filter(input => input.type === "checkbox");
    const radios = Array.from(formInputs).filter(input => input.type === "radio");
    
    if (!checkboxes.length && !radios.length) return;
    
    checkboxes.forEach(checkbox => {
        checkbox.addEventListener('change', (e) => {
            const otherCheckedCheckboxes = Array.from(checkboxes).filter(checkbox => checkbox !== e.target && checkbox.checked);
            if (otherCheckedCheckboxes.length > 0) {
                const checked = e.target.checked;
                const label = e.target.nextElementSibling.textContent;
                listItems.forEach(listItem => {
                    if (listItem.textContent.includes(label)) {
                        if (checked) {
                            listItem.style.display = 'block';
                            e.target.closest('label').classList.add('vdx--checked');
                        } 
                        if (!checked) {
                            if (!otherCheckedCheckboxes.some(checkbox => listItem.textContent.includes(checkbox.nextElementSibling.textContent))) {
                                listItem.style.display = 'none';
                                e.target.closest('label').classList.remove('vdx--checked');
                            }
                        }
                        if (checkboxes.every(checkbox => !checkbox.checked)) {
                            listItems.forEach(listItem => {
                                listItem.style.display = 'block';
                                e.target.closest('label').classList.remove('vdx--checked');
                            });
                        }
                    }
                    if (!listItem.textContent.includes(label)) {
                        if(checked) {   
                            e.target.closest('label').classList.add('vdx--checked');
                        }
                        else {
                            e.target.closest('label').classList.remove('vdx--checked');
                        }
                    }
                });
            }
            else {
                listItems.forEach(listItem => {
                    listItem.style.display = 'none';
                });
                const checked = e.target.checked;
                const label = e.target.nextElementSibling.textContent;
                listItems.forEach(listItem => {
                    if (listItem.textContent.includes(label)) {
                        if (checked) {
                            listItem.style.display = 'block';
                            e.target.closest('label').classList.add('vdx--checked');
                        } 
                        else {
                            listItems.forEach(listItem => {
                                listItem.style.display = 'block';
                                e.target.closest('label').classList.remove('vdx--checked');
                            });
                        }
                    }
                    if (!listItem.textContent.includes(label)) {
                        if(checked) {   
                            e.target.closest('label').classList.add('vdx--checked');
                        }
                        else {
                            listItem.style.display = 'block';
                            e.target.closest('label').classList.remove('vdx--checked');
                        }
                    }
                });
            }
        });
    });

    radios.forEach(radio => {
        radio.addEventListener('change', (e) => {
            listItems.forEach(listItem => {
                listItem.style.display = 'none';
            });
            radios.forEach(radio => {
                radio.closest('label').classList.remove('vdx--checked');
            });
            const checked = e.target.checked;
            const label = e.target.nextElementSibling.textContent;
            if (checked) {
                listItems.forEach(listItem => {
                    if (listItem.textContent.includes(label)) {
                        listItem.style.display = 'block';
                        e.target.closest('label').classList.add('vdx--checked');
                    }
                });
            }
        });
    });

    const search = form[0].querySelector('[vdx-filters-search]');
    if (!search) return;
    
    let searchTimer;
    search.addEventListener('input', (e) => {
        clearTimeout(searchTimer);
        searchTimer = setTimeout(() => {
            const searchValue = e.target.value.toLowerCase();
            listItems.forEach(listItem => {
                if (listItem.textContent.toLowerCase().includes(searchValue)) {
                    listItem.style.display = 'block';
                } else {
                    listItem.style.display = 'none';
                }
            });
        }, 500);
    });
}