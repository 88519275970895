// src/nthchild.js

export function addNthChildClass() {
    const elements = document.querySelectorAll('[vdx-nth-child]');
    
    elements.forEach(element => {
        const nthChildValue = element.getAttribute('vdx-nth-child');
        const children = Array.from(element.children);
        
        // Support for "1, 3, 3n+2" style selectors
        const nthChildValues = nthChildValue.split(', ');
        nthChildValues.forEach(value => {
            if (value.includes('n')) {
                const [multiplier, offset] = value.split('n+');
                const multiplierInt = parseInt(multiplier);
                const offsetInt = parseInt(offset);
                children.forEach((child, index) => {
                    if ((index + 1) % multiplierInt === offsetInt) {
                        child.classList.add(`vdx--nth-child-${multiplier}n-${offset}`);
                        // Add the class to all child elements of the nth child
                        child.querySelectorAll('*').forEach(childElement => {
                            childElement.classList.add(`vdx--nth-child-${multiplier}n-${offset}`);
                        });
                    }
                });
            } else {
                const nthChildIndex = parseInt(value, 10) - 1; // Convert to zero-based index
                
                const child = element.children[nthChildIndex];
                if (child) {
                    child.classList.add(`vdx--nth-child-${value}`);
                    // Add the class to all child elements of the nth child
                    child.querySelectorAll('*').forEach(childElement => {
                        childElement.classList.add(`vdx--nth-child-${value}`);
                    });
                }
            }
        });
    });
}



