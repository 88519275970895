export const popupsIntent = () => {
    // Utility functions
    const convertHoursToMilliseconds = (hours) => hours * 60 * 60 * 1000;
    const getLastShownTime = (key) => parseInt(localStorage.getItem(key)) || 0;
    const updateLastShownTime = (key) => localStorage.setItem(key, Date.now().toString());
    const shouldShowPopup = (key, frequency) => Date.now() - getLastShownTime(key) >= convertHoursToMilliseconds(frequency);
    
    // Scroll management
    const disableScroll = () => document.body.style.overflow = "hidden";
    const enableScroll = () => document.body.style.overflow = "";
    
    // Core popup functions
    const hidePopup = (popup, key) => {
        popup.style.opacity = "0";
        popup.classList.remove('vdx--open');
        popup.querySelectorAll('*').forEach(child => child.classList.remove('vdx--open'));
        
        // Remove tab trap if it exists
        if (popup._removeTabTrap) {
            popup._removeTabTrap();
            delete popup._removeTabTrap;
        }
        
        setTimeout(() => {
            popup.style.display = "none";
            enableScroll();
            if (key) updateLastShownTime(key);
        }, 500);
    };
    
    const showPopup = (popup, key) => {
        popup.style.display = "block";
        disableScroll();
        
        // Focus management
        let focusableElements;
        
        // Set up focus trap
        const handleTabKey = (e) => {
            if (e.key !== 'Tab' || !focusableElements?.length) return;
            
            const firstFocusable = focusableElements[0];
            const lastFocusable = focusableElements[focusableElements.length - 1];
            
            if (e.shiftKey && document.activeElement === firstFocusable) {
                e.preventDefault();
                lastFocusable.focus();
            } 
            else if (!e.shiftKey && document.activeElement === lastFocusable) {
                e.preventDefault();
                firstFocusable.focus();
            }
        };
        
        document.addEventListener('keydown', handleTabKey);
        popup._removeTabTrap = () => document.removeEventListener('keydown', handleTabKey);
        
        setTimeout(() => {
            popup.style.opacity = "1";
            
            // Add open classes
            popup.classList.add('vdx--open');
            popup.querySelectorAll('*').forEach(child => child.classList.add('vdx--open'));
    
            // Find focusable elements
            focusableElements = Array.from(popup.querySelectorAll(
                '[vdx-popup-focus], [vdx-popup-close], a[href]:not([disabled]), [role=button], button:not([disabled]), input:not([disabled]), textarea:not([disabled]), select:not([disabled]), [tabindex]:not([tabindex="-1"])'
            ));
            
            // Priority focus handling
            const priorityFocusElement = popup.querySelector('[vdx-popup-focus]');
            
            setTimeout(() => {
                if (priorityFocusElement) {
                    priorityFocusElement.focus();
                } else if (focusableElements.length >= 2) {
                    focusableElements[1].focus(); // Secondary focus
                } else if (focusableElements.length > 0) {
                    focusableElements[0].focus(); // Default focus
                }
            }, 100);
        }, 10);
        
        if (key) updateLastShownTime(key);
    };
    
    // Common popup setup function
    const setupPopupEvents = (popup, closeTriggers, storageKey = null) => {
        // Set initial state
        popup.style.opacity = "0";
        popup.style.display = "none";
        
        // Add click event to close triggers
        closeTriggers.forEach(trigger => {
            trigger.addEventListener("click", (e) => {
                e.preventDefault();
                hidePopup(popup, storageKey);
            });
        });
        
        // Close when clicking outside the popup content
        popup.addEventListener("click", (e) => {
            if (e.target === popup) {
                hidePopup(popup, storageKey);
            }
        });
        
        return popup;
    };
    
    // Popup type handlers
    let popupOnScrollDownElement, popupOnExitElement, popupOnScrollUpElement;
    
    const setupPopupModals = () => {
        const popups = document.querySelectorAll("[vdx-popup-popup]");
        if (popups.length === 0) return;
        
        popups.forEach(popup => {
            const popupId = popup.getAttribute("vdx-popup-popup");
            
            if (!popupId) {
                console.warn("Popup element is missing the popup ID value in vdx-popup-popup attribute");
                return;
            }
            
            const openTriggers = document.querySelectorAll(`[vdx-popup-open="${popupId}"]`);
            const closeTriggers = popup.querySelectorAll("[vdx-popup-close]");
            
            setupPopupEvents(popup, closeTriggers);
            
            // Add click event to open triggers
            openTriggers.forEach(trigger => {
                trigger.addEventListener("click", (e) => {
                    e.preventDefault();
                    showPopup(popup);
                });
            });
        });
    };

    const handleScrollDownPopup = () => {
        popupOnScrollDownElement = document.querySelector("[vdx-popup-scrolldown]");
        if (!popupOnScrollDownElement) return;
        
        const scrollThreshold = parseInt(popupOnScrollDownElement.getAttribute("vdx-popup-scrolldown")) || 50;
        const frequency = parseInt(popupOnScrollDownElement.getAttribute("vdx-popup-frequency")) || 0;
        const storageKey = "popupOnScrollDownShown";
        const closeTriggers = popupOnScrollDownElement.querySelectorAll("[vdx-popup-close]");
        
        if (closeTriggers.length === 0) {
            console.warn("Popup element is missing the close trigger in vdx-popup-close attribute");
            return;
        }

        setupPopupEvents(popupOnScrollDownElement, closeTriggers, storageKey);

        if (shouldShowPopup(storageKey, frequency)) {
            const scrollListener = () => {
                const scrollPercent = (window.scrollY + window.innerHeight) / document.documentElement.scrollHeight * 100;
                if (scrollPercent >= scrollThreshold) {
                    showPopup(popupOnScrollDownElement, storageKey);
                    window.removeEventListener("scroll", scrollListener);
                }
            };
            
            window.addEventListener("scroll", scrollListener);
        }
    };

    const handleExitPopup = () => {
        popupOnExitElement = document.querySelector("[vdx-popup-onexit]");
        if (!popupOnExitElement) return;
        
        const frequency = parseInt(popupOnExitElement.getAttribute("vdx-popup-frequency")) || 0;
        const storageKey = "popupOnExitShown";
        const closeTriggers = popupOnExitElement.querySelectorAll("[vdx-popup-close]");
        
        if (closeTriggers.length === 0) {
            console.warn("Popup element is missing the close trigger in vdx-popup-close attribute");
            return;
        }

        setupPopupEvents(popupOnExitElement, closeTriggers, storageKey);

        if (shouldShowPopup(storageKey, frequency)) {
            const exitListener = (event) => {
                // Check if the mouse has left the viewport
                if (event.clientY <= 10 || event.clientY >= window.innerHeight - 10) {
                    showPopup(popupOnExitElement, storageKey);
                    document.removeEventListener("mouseout", exitListener);
                }
            };

            document.addEventListener("mouseout", exitListener);
        }
    };

    const handleScrollUpPopup = () => {
        popupOnScrollUpElement = document.querySelector("[vdx-popup-scrollup]");
        if (!popupOnScrollUpElement) return;
        
        const scrollThreshold = parseInt(popupOnScrollUpElement.getAttribute("vdx-popup-scrollup")) || 10;
        const frequency = parseInt(popupOnScrollUpElement.getAttribute("vdx-popup-frequency")) || 0;
        const storageKey = "popupOnScrollUpShown";
        const closeTriggers = popupOnScrollUpElement.querySelectorAll("[vdx-popup-close]");
        
        if (closeTriggers.length === 0) return;

        setupPopupEvents(popupOnScrollUpElement, closeTriggers, storageKey);

        if (shouldShowPopup(storageKey, frequency)) {
            let lastScrollY = window.scrollY;
            
            const scrollListener = () => {
                const currentScrollY = window.scrollY;
                if (lastScrollY > currentScrollY && (lastScrollY - currentScrollY) / window.innerHeight * 100 >= scrollThreshold) {
                    showPopup(popupOnScrollUpElement, storageKey);
                    window.removeEventListener("scroll", scrollListener);
                }
                lastScrollY = currentScrollY;
            };
            
            window.addEventListener("scroll", scrollListener);
        }
    };

    // Global event handlers
    const setupGlobalEscapeHandler = () => {
        document.addEventListener('keydown', function(event) {
            if (event.key === "Escape") {
                // Close all visible popups
                const popupTypes = [
                    { element: document.querySelectorAll("[vdx-popup-popup]"), key: null },
                    { element: [popupOnScrollDownElement], key: 'popupOnScrollDownShown' },
                    { element: [popupOnExitElement], key: 'popupOnExitShown' },
                    { element: [popupOnScrollUpElement], key: 'popupOnScrollUpShown' }
                ];
                
                popupTypes.forEach(({ element, key }) => {
                    if (!element) return;
                    
                    (Array.isArray(element) ? element : [element]).forEach(popup => {
                        if (popup && popup.style.display === 'block') {
                            hidePopup(popup, key);
                        }
                    });
                });
            }
        });
    };

    // Initialize all popup types
    setupPopupModals();
    handleScrollDownPopup();
    handleExitPopup();
    handleScrollUpPopup();
    setupGlobalEscapeHandler();
};
