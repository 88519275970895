import { addNthChildClass } from './nthchild.js';

export const cmsRandomSort = () => {
    const shuffleElements = document.querySelectorAll('[vdx-sort-random]');
    const sortChildren = (parent, maxChildren) => {
        const children = Array.from(parent.children);
        children.sort(() => Math.random() - 0.5);
        if (maxChildren) {
            children.slice(maxChildren).forEach(child => {
                child.style.display = 'none';
                child.style.opacity = '0';
            });
            children.slice(0, maxChildren).forEach(child => {
                parent.appendChild(child);
                child.style.display = 'block';
                child.style.opacity = '0';
                setTimeout(() => {
                    child.style.opacity = '1';
                }, 0);
            });
        } else {
            children.forEach(child => {
                parent.appendChild(child);
            });
        }
    };

    shuffleElements.forEach(parent => {
        const maxChildren = parseInt(parent.getAttribute('vdx-sort-random') || 999);
        sortChildren(parent, maxChildren);
    });

    const sortButton = document.querySelector('[vdx-sort-button]');
    if (sortButton) {
        sortButton.addEventListener('click', (event) => {
            event.preventDefault();
            shuffleElements.forEach(parent => {
                const maxChildren = parseInt(parent.getAttribute('vdx-sort-random') || 999);
                sortChildren(parent, maxChildren);
            });
        });
    }

    setTimeout(() => {
        addNthChildClass();
    }, 0);
}