export function mirrorClick() {
    // Select all elements with the vdx-mirrorclick-button attribute
    const buttons = document.querySelectorAll('[vdx-mirrorclick-button]');
    if (buttons.length === 0) return;

    buttons.forEach(button => {
        button.addEventListener('click', () => {
            // Get the value of the vdx-mirrorclick-this attribute from the clicked button
            const mirrorValue = button.getAttribute('vdx-mirrorclick-button');
            // Find the corresponding element with the matching vdx-mirrorclick-this attribute
            const target = document.querySelectorAll(`[vdx-mirrorclick-this="${mirrorValue}"]`);

            // If the target exists, trigger a click on it
            if (target) {
                target.forEach(t => t.click());
            }
        });
    });
}

// Call the setup function to initialize the mirror click functionality
mirrorClick();

