import { cmsNest } from './cmsnest.js';

// Shared utility functions
const setActiveClasses = (element, isActive, activeClass = 'vdx--active') => {
    element.classList.toggle(activeClass, isActive);
    element.querySelectorAll('*').forEach(child => {
        child.classList.toggle(activeClass, isActive);
        void child.offsetWidth; // Force reflow
    });
};

const createTimedRotation = ({ container, elements, interval, pauseDelay, onRotate }) => {
    let currentIndex = 0;
    let intervalId = null;
    let hoverTimeoutId = null;

    const startRotation = (delay = 0) => {
        clearInterval(intervalId);
        clearTimeout(hoverTimeoutId);
        
        hoverTimeoutId = setTimeout(() => {
            intervalId = setInterval(() => {
                currentIndex = (currentIndex + 1) % elements.length;
                onRotate(elements[currentIndex], currentIndex);
            }, interval);
        }, delay);
    };

    const stopRotation = () => {
        clearInterval(intervalId);
        clearTimeout(hoverTimeoutId);
    };

    container.addEventListener("mouseenter", stopRotation);
    container.addEventListener("mouseleave", () => {
        if (pauseDelay) startRotation(pauseDelay);
    });

    return {
        start: startRotation,
        stop: stopRotation,
        getCurrentIndex: () => currentIndex,
        setCurrentIndex: (index) => { currentIndex = index; }
    };
};

let isScrolling = false;

// Set up a scroll event listener to update the isScrolling flag
let isScrollingTimeout;
window.addEventListener('scroll', () => {
    isScrolling = true;
    if (isScrollingTimeout) clearTimeout(isScrollingTimeout);
    isScrollingTimeout = setTimeout(() => {
        isScrolling = false;
    }, 100); // Adjust the timeout as needed
});

export const customTabs = () => {
    const loadTabContent = async (tabButton, tabsContainer) => {
        const tabValue = tabButton.getAttribute("vdx-tabs-button");
        const url = tabButton.tagName === 'A' ? tabButton.getAttribute("href") : window.location.href;

        try {
            const response = await fetch(url);
            if (!response.ok) throw new Error(`Failed to fetch content: ${response.statusText}`);
            const doc = new DOMParser().parseFromString(await response.text(), "text/html");

            const selectedPane = doc.querySelector(`[vdx-tabs-pane="${tabValue}"]`);
            const targetContainer = tabsContainer.querySelector("[vdx-tabs-content]");
            if (!selectedPane || !targetContainer) throw new Error(`Matching elements not found for tab "${tabValue}"`);

            targetContainer.innerHTML = "";
            tabsContainer.querySelectorAll(".vdx--active").forEach(el => el.classList.remove("vdx--active"));
            tabsContainer.querySelectorAll("[vdx-tabs-button]").forEach(btn => btn.setAttribute("aria-selected", "false"));
            
            setActiveClasses(tabButton, true);
            tabButton.setAttribute("aria-selected", "true");

            const paneClone = selectedPane.cloneNode(true);
            targetContainer.appendChild(paneClone);
            setActiveClasses(paneClone, true);

            // Reinitialize cmsNest after content is loaded
            if (typeof cmsNest === 'function') {
                await cmsNest();
            }

        } catch (error) {
            console.error("Error loading tab content:", error);
        }
    };

    const initializeTimedTabs = (tabsContainer) => {
        const tabButtons = Array.from(tabsContainer.querySelectorAll("[vdx-tabs-button]"));
        if (tabButtons.length === 0) return;

        const switchInterval = parseInt(tabsContainer.getAttribute("vdx-tabs-timed")) || 3000;
        const pauseDelay = parseInt(tabsContainer.getAttribute("vdx-tabs-pause")) || 0;

        const rotation = createTimedRotation({
            container: tabsContainer,
            elements: tabButtons,
            interval: switchInterval,
            pauseDelay,
            onRotate: (button) => loadTabContent(button, tabsContainer)
        });

        rotation.start();
    };

    const onTabClick = (event) => {
        const tabButton = event.target.closest("[vdx-tabs-button]");
        if (tabButton) {
            event.preventDefault();
            const tabsContainer = tabButton.closest("[vdx-tabs-tab]");
            if (tabsContainer) loadTabContent(tabButton, tabsContainer);
        }
    };

    const initializeTabs = () => {
        document.querySelectorAll("[vdx-tabs-tab]").forEach(tabsContainer => {
            const firstTab = tabsContainer.querySelector("[vdx-tabs-button]");
            if (firstTab) {
                loadTabContent(firstTab, tabsContainer);
                if (tabsContainer.hasAttribute("vdx-tabs-timed")) {
                    initializeTimedTabs(tabsContainer);
                }
            }
        });
    };

    document.addEventListener("click", onTabClick);
    window.addEventListener("load", initializeTabs);
};

export const timedTabs = () => {
    document.querySelectorAll("[vdx-tabs-timed]:not([vdx-tabs-tab])").forEach((tabGroup) => {
        const tabs = Array.from(tabGroup.querySelectorAll(".w-tab-link"));
        const tabPanes = Array.from(tabGroup.querySelectorAll(".w-tab-pane"));

        const switchInterval = parseInt(tabGroup.getAttribute("vdx-tabs-timed")) || 3000;
        const pauseDelay = parseInt(tabGroup.getAttribute("vdx-tabs-pause")) || 0;
        const fadeInDuration = parseInt(tabGroup.getAttribute("data-duration-in"));
        const fadeOutDuration = parseInt(tabGroup.getAttribute("data-duration-out"));
        const easingFunction = tabGroup.getAttribute("data-easing") || 'ease';

        let currentIndex = tabs.findIndex(tab => tab.classList.contains("w--current"));
        if (currentIndex === -1) currentIndex = 0;

        let intervalId, hoverTimeoutId;

        const setActiveClass = (element, isActive) => {
            if (!element.classList.contains('w-tab-link') && !element.classList.contains('w-tab-pane')) {
                return;
            }
            
            element.querySelectorAll('*').forEach(child => {
                child.classList.toggle('vdx--active', isActive);
                void child.offsetWidth; // Force reflow
            });
        };

        const switchToTab = (index) => {
            const currentPane = tabPanes[currentIndex];
            const newPane = tabPanes[index];

            currentPane.style.transition = `opacity ${fadeOutDuration}ms ${easingFunction}`;
            currentPane.style.opacity = '0';

            setTimeout(() => {
                currentPane.classList.remove("w--tab-active");
                currentIndex = index;
                newPane.classList.add("w--tab-active");
                
                newPane.style.transition = `opacity ${fadeInDuration}ms ${easingFunction}`;
                newPane.style.opacity = '1';

                tabs.forEach((tab, tabIndex) => {
                    const isActive = tabIndex === index;
                    tab.classList.toggle("w--current", isActive);
                    tab.setAttribute("aria-selected", isActive);
                    setActiveClass(tab, isActive);
                    setActiveClass(tabPanes[tabIndex], isActive);
                });
            }, fadeOutDuration);
        };

        const startTimedRotation = (delay = 0) => {
            clearInterval(intervalId);
            clearTimeout(hoverTimeoutId);
            
            hoverTimeoutId = setTimeout(() => {
                intervalId = setInterval(() => {
                    switchToTab((currentIndex + 1) % tabs.length);
                }, switchInterval);
            }, delay);

            setActiveClass(tabs[currentIndex], true);
            setActiveClass(tabPanes[currentIndex], true);
        };

        const stopTimedRotation = () => {
            clearInterval(intervalId);
            clearTimeout(hoverTimeoutId);
        };

        tabGroup.addEventListener("mouseenter", () => {
            if (!isScrolling) { // Check if the user is scrolling
                stopTimedRotation();
            }
        });

        if (tabGroup.hasAttribute("vdx-tabs-pause")) {
            tabGroup.addEventListener("mouseleave", () => {
                startTimedRotation();
            });
        }

        tabs.forEach((tab, index) => {
            tab.addEventListener("click", () => {
                stopTimedRotation();
                switchToTab(index);
                tab.classList.add("vdx--active");
                tab.querySelectorAll('*').forEach(child => {
                    child.classList.add("vdx--active");
                });
                if (pauseDelay) startTimedRotation(pauseDelay);
            });
        });

        startTimedRotation();
    });
};