export const copyToClipboard = () => {
    const hideAllAlerts = () => {
        document.querySelectorAll("[vdx-copy-alert]").forEach(alert => {
            alert.style.display = "none";
        });
    };

    const showAlert = (alertElement, defaultAlert) => {
        if (alertElement) {
            alertElement.style.display = "block";
        } else if (defaultAlert) {
            defaultAlert.style.display = "block";
        }
        setTimeout(() => {
            if (alertElement) alertElement.style.display = "none";
            if (defaultAlert) defaultAlert.style.display = "none";
        }, 2000);
    };

    const displayCopyError = (element, message) => {
        element.setAttribute("title", message);
        element.style.border = "1px dotted red";
        element.style.cursor = "help";
    };

    hideAllAlerts();

    document.querySelectorAll("[vdx-copy-button]").forEach(button => {
        const targetId = button.getAttribute("vdx-copy-button");
        const targetElement = document.querySelector(`[vdx-copy-this="${targetId}"]`);
        const alertElement = targetId ? document.querySelector(`[vdx-copy-alert="${targetId}"]`) : null;
        const defaultAlert = document.querySelector('[vdx-copy-alert=""]');

        if (targetElement) {
            button.addEventListener("click", event => {
                let textToCopy;
                if (targetElement.hasAttribute("href")) {
                    let href = targetElement.getAttribute("href");
                    if (href.startsWith("mailto:")) {
                        textToCopy = href.replace("mailto:", "");
                        event.preventDefault();
                    } else if (href.startsWith("tel:")) {
                        textToCopy = href.replace("tel:", "");
                        event.preventDefault();
                    } else if (href === "#") {
                        textToCopy = window.location.href.split("#")[0];
                        event.preventDefault();
                    } else {
                        textToCopy = new URL(href, window.location.origin).href;
                    }
                } else {
                    textToCopy = targetElement.textContent || targetElement.href;
                }
                navigator.clipboard.writeText(textToCopy).then(() => {
                    showAlert(alertElement, defaultAlert);
                }).catch(err => {
                    displayCopyError(button, "Error: Failed to copy text. Please try again.");
                });
            });
        } else {
            displayCopyError(button, `Error: No matching element with vdx-copy-this="${targetId}" found.`);
        }
    });

    document.querySelectorAll("[vdx-copy-this]").forEach(targetElement => {
        const targetId = targetElement.getAttribute("vdx-copy-this");
        if (!document.querySelector(`[vdx-copy-button="${targetId}"]`)) {
            displayCopyError(targetElement, `Error: No matching button with vdx-copy-button="${targetId}" found.`);
        }
    });
};