// Function to replace footnotes notation with links
export function generateFootnotes() {
    const articleContent = document.querySelectorAll('[vdx-footnotes-article]');
    if (articleContent.length === 0) return;
    const footnoteContent = document.querySelectorAll('[vdx-footnotes-footnotes]');
    if (footnoteContent.length === 0) return;

    const footnoteRegex = /\[\d+\]/g;

    articleContent.forEach(content => {
        const footnotes = content.textContent.match(footnoteRegex);
      
        if (footnotes) {
            footnotes.forEach((footnote) => {
                const footnoteNumber = footnote.replace(/\[|\]/g, '');
                
                const footnoteLink = document.createElement('a');
                footnoteLink.href = `#vdx-fn-footnote-${footnoteNumber}`;
                footnoteLink.id = `vdx-fn-article-${footnoteNumber}`;
                footnoteLink.setAttribute('vdx-footnotes-link', '');
                footnoteLink.classList.add('vdx--fn-article-link');
                footnoteLink.textContent = footnote;
                content.innerHTML = content.innerHTML.replace(footnote, footnoteLink.outerHTML);
            });
        }
    });

    footnoteContent.forEach(content => {
        const footnoteNumbers = content.textContent.match(footnoteRegex);
        if (!footnoteNumbers) return;
        footnoteNumbers.forEach((footnote) => {
            const footnoteNumber = footnote.replace(/\[|\]/g, '');
            const footnoteLink = document.createElement('a');
            footnoteLink.href = `#vdx-fn-article-${footnoteNumber}`;
            footnoteLink.id = `vdx-fn-footnote-${footnoteNumber}`;
            footnoteLink.classList.add('vdx--fn-footnote-link');
            footnoteLink.textContent = footnote;
            content.innerHTML = content.innerHTML.replace(footnote, footnoteLink.outerHTML);
        });
    });

    if (articleContent[0].getAttribute('vdx-footnotes-article') === 'hover') {
        const footnoteLinks = document.querySelectorAll('[vdx-footnotes-link]');
        footnoteLinks.forEach(link => {
            link.addEventListener('mouseover', () => {
                const footnoteNumber = link.textContent.replace(/\[|\]/g, '');
                const footnoteID = document.querySelector(`#vdx-fn-footnote-${footnoteNumber}`);
                const siblingText = footnoteID.nextSibling.textContent;
                const tooltip = document.createElement('div');
                tooltip.classList.add('vdx--fn-footnote');
                tooltip.textContent = siblingText;
                link.appendChild(tooltip);
                link.addEventListener('mouseleave', () => {
                    tooltip.remove();
                });
            });
        });
    }
}
