export const currentTime = () => {
    const padZero = (number, length) => number.toString().padStart(length, "0");
    const convertHourFormat = (hour, format) => format === "12-hour" ? hour % 12 || 12 : hour;

    const updateTime = () => {
        const now = new Date();

        document.querySelectorAll("[vdx-time-currenttime]").forEach(element => {
            const timezone = element.getAttribute("vdx-time-timezone") || Intl.DateTimeFormat().resolvedOptions().timeZone;
            const clockFormat = element.getAttribute("vdx-time-clock") || "24-hour";
            const options = { timeZone: timezone };
            const localTime = new Date(now.toLocaleString("en-US", options));
            const hours = convertHourFormat(localTime.getHours(), clockFormat);
            const minutes = padZero(localTime.getMinutes(), 2);
            const seconds = padZero(localTime.getSeconds(), 2);
            const suffix = clockFormat === "12-hour" ? (localTime.getHours() >= 12 ? " PM" : " AM") : "";
            element.textContent = `${padZero(hours, 2)}:${minutes}:${seconds}${suffix}`;
        });

        document.querySelectorAll("[vdx-time-min]").forEach(element => {
            let minutes = now.getMinutes();
            minutes = (element.getAttribute("vdx-time-display") || "2-digit") === "1-digit" ? minutes.toString() : padZero(minutes, 2);
            element.textContent = minutes;
        });

        document.querySelectorAll("[vdx-time-hour]").forEach(element => {
            const timezone = element.getAttribute("vdx-time-timezone") || Intl.DateTimeFormat().resolvedOptions().timeZone;
            const clockFormat = element.getAttribute("vdx-time-clock") || "24-hour";
            const options = { timeZone: timezone };
            const localTime = new Date(now.toLocaleString("en-US", options));
            let hours = convertHourFormat(localTime.getHours(), clockFormat);
            hours = (element.getAttribute("vdx-time-display") || "2-digit") === "1-digit" ? hours.toString() : padZero(hours, 2);
            const suffix = clockFormat === "12-hour" ? (localTime.getHours() >= 12 ? " PM" : " AM") : "";
            element.textContent = hours + suffix;
        });

        document.querySelectorAll("[vdx-time-seconds]").forEach(element => {
            let seconds = now.getSeconds();
            seconds = (element.getAttribute("vdx-time-display") || "2-digit") === "1-digit" ? seconds.toString() : padZero(seconds, 2);
            element.textContent = seconds;
        });
    };

    updateTime();
    setInterval(updateTime, 1000);
};
