export const generateTOC = () => {
    // Check if the function has already been initialized to prevent double execution
    if (window.vdxTOCInitialized) return;
    window.vdxTOCInitialized = true;
    
    const tocList = document.querySelector("[vdx-toc-list]");
    const tocArticle = document.querySelector("[vdx-toc-article]");

    if (!tocList || !tocArticle) return;
   
    const headings = tocArticle.querySelectorAll("h1, h2, h3, h4, h5, h6");
    if (headings.length === 0) return;

    // Wrap each header and its following content in a div with the id (no longer assigning id to headers)
    headings.forEach((heading) => {
        const wrapperDiv = document.createElement("div");
        wrapperDiv.id = heading.textContent.trim().replace(/[^a-zA-Z0-9\s-]/g, '').replace(/\s+/g, '-').toLowerCase(); // Set id based on the heading text, keeping numbers

        // Insert the wrapperDiv before the heading
        heading.parentNode.insertBefore(wrapperDiv, heading);

        // Move the heading inside the wrapperDiv
        wrapperDiv.appendChild(heading);
       
        // Move the following siblings into the wrapperDiv until the next heading or end
        let nextSibling = wrapperDiv.nextElementSibling;
        while (nextSibling && !nextSibling.tagName.match(/^H[1-6]$/i)) {
            const sibling = nextSibling;
            nextSibling = nextSibling.nextElementSibling;
            wrapperDiv.appendChild(sibling);
        }
    });

    // Continue with the existing TOC generation logic
    ["h1", "h2", "h3", "h4", "h5", "h6"].forEach(tag => {
        let tocHeading = tocList.querySelector(`[vdx-toc-${tag}]`);
        if (tocHeading) {
            tocHeading.style.display = "none";
        }
    });

    headings.forEach((heading) => {
        const tagName = heading.tagName.toLowerCase();
        const tocHeading = tocList.querySelector(`[vdx-toc-${tagName}]`);
        if (tocHeading) {
            const listItem = document.createElement("li");
            if (tocHeading.className) listItem.className = tocHeading.className;

            const anchor = document.createElement("a");
            anchor.href = `#${heading.textContent.trim().replace(/[^a-zA-Z0-9\s-]/g, '').replace(/\s+/g, '-').toLowerCase()}`;  // Now points to the heading text
            anchor.textContent = heading.textContent;

            const tocAnchor = tocHeading.querySelector("a");
            if (tocAnchor && tocAnchor.className) {
                anchor.className = tocAnchor.className; // Assign class to the anchor only
            }

            listItem.appendChild(anchor);
            tocList.appendChild(listItem);
        }
    });
};
