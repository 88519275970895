export const backButtons = () => {
    document.querySelectorAll("[vdx-back-button]").forEach(button => {
        const goBack = (event) => {
            event.preventDefault();
            window.history.back();

            // Remove both event listeners after one has been triggered
            button.removeEventListener("click", goBack);
            button.removeEventListener("mouseup", goBack);
        };

        // Add event listeners for both click and mouseup
        button.addEventListener("click", goBack);
        button.addEventListener("mouseup", goBack);
    });
};
