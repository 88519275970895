export const socialShare = () => {
    const getCurrentPageDetails = () => ({
        url: window.location.href,
        title: document.title,
    });

    // Initialize the alert element to be hidden on load
    const alertElements = document.querySelectorAll("[vdx-share-alert]");
    alertElements.forEach(element => {
        element.style.display = "none";
        element.style.opacity = "0";
    });

    document.querySelectorAll("[vdx-share-facebook]").forEach(element => {
        element.addEventListener("click", () => {
            const { url } = getCurrentPageDetails();
            const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
            window.open(shareUrl, "_blank");
        });
    });

    document.querySelectorAll("[vdx-share-twitter]").forEach(element => {
        element.addEventListener("click", () => {
            const { url, title } = getCurrentPageDetails();
            const shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(title)}`;
            window.open(shareUrl, "_blank");
        });
    });

    document.querySelectorAll("[vdx-share-linkedin]").forEach(element => {
        element.addEventListener("click", () => {
            const { url, title } = getCurrentPageDetails();
            const shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}&title=${encodeURIComponent(title)}`;
            window.open(shareUrl, "_blank");
        });
    });

    document.querySelectorAll("[vdx-share-page]").forEach(element => {
        element.addEventListener("click", () => {
            const { url } = getCurrentPageDetails();
            navigator.clipboard.writeText(url)
                .then(() => {
                    const alertElements = document.querySelectorAll(`[vdx-share-alert='${element.getAttribute('vdx-share-page')}']`);
                    alertElements.forEach(alertElement => {
                        alertElement.style.display = "block";
                        alertElement.style.opacity = "1";
                        setTimeout(() => {
                            alertElement.style.opacity = "0";
                            setTimeout(() => { alertElement.style.display = "none"; }, 1000);
                        }, 2000);
                    });
                })
                .catch(err => console.error("Error copying URL:", err));
        });
    });

    document.querySelectorAll("[vdx-share-whatsapp]").forEach(element => {
        element.addEventListener("click", () => {
            const { url, title } = getCurrentPageDetails();
            const shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(title)}%20${encodeURIComponent(url)}`;
            window.open(shareUrl, "_blank");
        });
    });

    

    document.querySelectorAll("[vdx-share-email]").forEach(element => {
        element.addEventListener("click", () => {
            const { url, title } = getCurrentPageDetails();
            let subject, body;
            switch (element.getAttribute('vdx-share-email')) {
                case 'fr':
                    subject = `Vérifiez cette page: ${title}`;
                    body = `J'ai trouvé cela intéressant et je voulais le partager avec toi: ${url}`;
                    break;
                case 'es':
                    subject = `Echa un vistazo a esta página: ${title}`;
                    body = `Encontré esto interesante y quería compartirla contigo: ${url}`;
                    break;
                case 'de':
                    subject = `Schau dir diese Seite an: ${title}`;
                    body = `Ich fand dies interessant und wollte es mit dir teilen: ${url}`;
                    break;
                default:
                    subject = `Check out this page: ${title}`;
                    body = `I found this interesting and wanted to share it with you: ${url}`;
            }
            const mailtoLink = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
            window.location.href = mailtoLink;
        });
    });
};

