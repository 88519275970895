// countdown.js

export function countdown(element, startDate, endDate) {
    const daysElement = element.querySelector("[vdx-countdown-days]");
    const hoursElement = element.querySelector("[vdx-countdown-hours]");
    const minsElement = element.querySelector("[vdx-countdown-mins]");
    const secsElement = element.querySelector("[vdx-countdown-secs]");

    function updateCountdown() {
        const now = new Date().getTime();
        const distance = endDate - (now > startDate ? now : startDate);

        if (distance < 0) {
            clearInterval(countdownInterval);
            daysElement.textContent = "0";
            hoursElement.textContent = "0";
            minsElement.textContent = "0";
            secsElement.textContent = "0";
            return;
        }

        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        daysElement.textContent = days;
        hoursElement.textContent = hours;
        minsElement.textContent = minutes;
        secsElement.textContent = seconds;
    }

    const countdownInterval = setInterval(updateCountdown, 1000);
    updateCountdown();
}

export function initializeCountdowns() {
    // Get all countdown elements on the page
    const countdownElements = document.querySelectorAll("[vdx-countdown-enddate]");

    countdownElements.forEach(element => {
        const endDateAttr = element.getAttribute("vdx-countdown-enddate");
        const startDateAttr = element.getAttribute("vdx-countdown-startdate");

        // Get the end date from the attribute
        const endDate = new Date(endDateAttr).getTime();

        // If a start date is provided, use it; otherwise, use the current date
        const startDate = startDateAttr ? new Date(startDateAttr).getTime() : new Date().getTime();

        // Start the countdown for this element
        countdown(element, startDate, endDate);
    });
}
