export const transitionDelay = () => {
    const getTransitionDelay = (element) => {
        while (element) {
            const delay = element.getAttribute("vdx-transition-delay");
            if (delay !== null) return delay;
            element = element.parentElement;
        }
        return null;
    };

    document.querySelectorAll("a").forEach(link => {
        const delay = getTransitionDelay(link);

        // Only add the event listener if vdx-transition-delay exists on the link or a parent element
        if (delay !== null) {
            link.addEventListener("click", (event) => {
                event.preventDefault();
                const url = link.href;

                if (delay === "false") {
                    setTimeout(() => {
                        window.location.href = url;
                    }, 0);
                } else {
                    const delayMs = parseInt(delay) || 0;
                    setTimeout(() => {
                        window.location.href = url;
                    }, delayMs);
                }
            });
        }
    });
};
