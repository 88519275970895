export const addSmoothScroll = () => {
  // Select the scroll offset element and calculate its height
  const scrollOffsetElement = document.querySelector("[vdx-scroll-offset]");
  if (!scrollOffsetElement) return;
  const scrollOffsetElementHeight = scrollOffsetElement ? scrollOffsetElement.getBoundingClientRect().height : 0;

  // Create and append the Webflow script to disable Webflow's default scroll behavior
  const script = document.createElement('script');
  script.textContent = `
    var Webflow = Webflow || [];
    Webflow.push(function() {
      $(document).off('click.wf-scroll');
    });
  `;
  document.head.appendChild(script);

  // Calculate and parse scroll offset values
  const scrollOffset = scrollOffsetElement ? scrollOffsetElement.getAttribute("vdx-scroll-offset") || scrollOffsetElementHeight : scrollOffsetElementHeight;
  const scrollOffsetValue = parseScrollOffset(scrollOffset);

  // Handle scrolling based on the presence of a hash in the URL
  const hash = window.location.hash;
  if (!hash) {
    //console.log("Target element not found");
    document.addEventListener('click', (event) => handleAnchorClick(event, scrollOffset));
  } else {
    scrollToTargetElement(hash, scrollOffsetValue);
  }

  // Add event listener for click events to handle anchor clicks
  document.addEventListener('click', (event) => handleAnchorClick(event, scrollOffset));
};

// Function to handle anchor click events
const handleAnchorClick = (event, scrollOffset) => {
  const target = event.target.closest('a[href^="#"]:not([href="#"])');

  if (!target) return;
  event.preventDefault(); // Prevent default anchor click behavior
  const targetElement = document.querySelector(target.getAttribute('href'));
  if (!targetElement) return;
  const scrollOffsetValue = parseScrollOffset(scrollOffset);
  const targetElementOffset = targetElement.getBoundingClientRect().top + window.scrollY - scrollOffsetValue;
  window.scrollTo({
    top: targetElementOffset,
    behavior: 'smooth'
  });
  // Update the URL after scrolling
  window.history.pushState({}, '', target.getAttribute('href'));
};

// Function to scroll to the target element
const scrollToTargetElement = (hash, scrollOffsetValue) => {
  const targetElement = document.querySelector(hash);
  if (targetElement) {
    const targetElementOffset = targetElement.getBoundingClientRect().top + window.scrollY - scrollOffsetValue;
    window.scrollTo({
      top: targetElementOffset,
      behavior: 'smooth'
    });
    //console.log("Scrolling to anchor: ", hash, "with offset: ", scrollOffsetValue);
  }
};

// Function to parse scroll offset values
const parseScrollOffset = (offset) => {
  const scrollOffsetElement = document.querySelector("[vdx-scroll-offset]");
  const scrollOffsetElementHeight = scrollOffsetElement ? scrollOffsetElement.getBoundingClientRect().height : 0;

  if (typeof offset !== 'string') {
    console.log("Invalid offset type: ", offset);
    return parseFloat(offset);
  }

  const baseFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
  const viewportHeight = window.innerHeight;
  const viewportWidth = window.innerWidth;

  switch (true) {
    case offset.endsWith('px'):
      return parseFloat(offset.replace('px', ''));
    case offset.endsWith('rem'):
      return parseFloat(offset.replace('rem', '')) * baseFontSize;
    case offset.endsWith('%'):
      return (parseFloat(offset.replace('%', '')) / 100) * scrollOffsetElementHeight;
    case offset.endsWith('vh'):
      return (parseFloat(offset.replace('vh', '')) / 100) * viewportHeight;
    case offset.endsWith('vw'):
      return (parseFloat(offset.replace('vw', '')) / 100) * viewportWidth;
    default:
      return parseFloat(offset);
  }
};
