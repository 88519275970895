import { functions } from './functions.js';

const addWebflowScripts = () => {
    try {
        Webflow.destroy();
        Webflow.ready();
        Webflow.require('ix2').init();
    } catch (error) {
        console.log('Error adding Webflow scripts:', error);
        return;
    }
};

const updateLink = async (link) => {
    const url = link.href;
    let html;
    
    // Check if content is in local storage
    const cachedContent = localStorage.getItem(url);
    if (cachedContent) {
        html = cachedContent; // Use cached content
    } else {
        // Fetch and cache the content
        const response = await fetch(url);
        html = await response.text();
        localStorage.setItem(url, html); // Store the fetched content in local storage
    }
    
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const nestedElement = doc.querySelector(`[vdx-nest-this='${link.getAttribute('vdx-nest-link')}']`);
    
    if (nestedElement && link.parentNode) {
        link.outerHTML = nestedElement.outerHTML;
    }
};



export const cmsNest = async () => {
    const nestLinks = Array.from(document.querySelectorAll("[vdx-nest-link]"));

    for (const link of nestLinks) {
        const url = link.href;
        let html;
        
        // Check if content is in local storage
        const cachedContent = localStorage.getItem(url);
        if (cachedContent) {
            html = cachedContent; // Use cached content
        } else {
            // Fetch and cache the content
            const response = await fetch(url);
            html = await response.text();
            localStorage.setItem(url, html); // Store the fetched content in local storage
        }

        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const nestedElement = doc.querySelector(`[vdx-nest-this='${link.getAttribute('vdx-nest-link')}']`);

        if (nestedElement && link.parentNode) {
            link.outerHTML = nestedElement.outerHTML;
        } else {
            if (!nestedElement) {
                console.warn('Cannot replace link: nestedElement is null.');
                link.style.border = '2px solid red';
                const tooltip = document.createElement('div');
                tooltip.textContent = `[vdx-nest-this='${link.getAttribute('vdx-nest-link')}'] does not have a corresponding [vdx-next-this='${link.getAttribute('vdx-nest-link')}'] attribute`;
                tooltip.style.position = 'absolute';
                tooltip.style.top = '100%';
                tooltip.style.left = '50%';
                tooltip.style.transform = 'translateX(-50%)';
                tooltip.style.backgroundColor = 'red';
                tooltip.style.color = 'white';
                tooltip.style.padding = '5px';
                tooltip.style.borderRadius = '5px';
                tooltip.style.zIndex = '1000';
                link.parentNode.insertBefore(tooltip, link.nextSibling);
                
                const moreNestLinks = document.querySelectorAll("[vdx-nest-link]");
                if (moreNestLinks.length > 1) {
                    const moreNestLinksArray = Array.from(moreNestLinks);
                    const errorLink = moreNestLinksArray[0];
                    const nextlinks = moreNestLinksArray.filter(link => link !== errorLink);
                    for (const link of nextlinks) {
                        await updateLink(link);
                    }
                    return;
                }
                return;
            }

            if (!link.parentNode) {
                console.warn('Cannot replace link: link has no parent node.');
                return;
            }
        }
    }

    functions();
    addWebflowScripts();
};