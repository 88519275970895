// removeelement.js

export function removeElements() {
    // Get all elements in the document with the attribute 'vdx-remove-element'
    const elementsWithAttribute = document.querySelectorAll("[vdx-remove-element]");
    
    // Loop through each element and check the attribute value
    elementsWithAttribute.forEach(element => {
      const attributeValue = element.getAttribute("vdx-remove-element");
  
      // If the attribute value is exactly "false", do nothing
      if (attributeValue === "false") {
        return;
      }
  
      // If the attribute value is a valid CSS selector, remove the child elements that match the selector
      if (attributeValue) {
        try {
          const childElements = element.querySelectorAll(attributeValue);
          childElements.forEach(child => {
            child.remove();
          });
        } catch (error) {
          console.error(`Invalid CSS selector: ${attributeValue}`, error);
        }
      } else {
        // If no valid selector, just remove the element itself
        element.remove();
      }
    });
  }
  