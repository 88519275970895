// toast.js
export const initializeToastButtons = () => {
    const toastMessages = document.querySelectorAll('[vdx-toast-message]');
    const toastButtons = document.querySelectorAll('[vdx-toast-button]');

    // Initially hide all toast messages by setting opacity to 0 and display to none
    toastMessages.forEach(toastMessage => {
        toastMessage.style.opacity = '0';
        toastMessage.style.display = 'none';

        // Add a single transitionend listener to hide after fade-out
        toastMessage.addEventListener('transitionend', (event) => {
            if (event.propertyName === 'opacity' && toastMessage.style.opacity === '0') {
                toastMessage.style.display = 'none';  // Hide once opacity reaches 0
            }
        });
    });

    // Set up event listeners for toast buttons
    toastButtons.forEach(button => {
        button.addEventListener('click', () => {
            const toastValue = button.getAttribute('vdx-toast-button');
            const toastMessage = document.querySelector(`[vdx-toast-message="${toastValue}"]`);

            if (toastMessage) {
                toastMessage.style.display = 'block';
                requestAnimationFrame(() => {
                    toastMessage.style.opacity = '1';  // Fade in the toast message
                });
                hideToast(toastMessage);
            }
        });
    });
};

// Function to hide the toast message after a delay
const hideToast = (toastMessage) => {
    const delay = parseInt(toastMessage.getAttribute('vdx-toast-delay')) || 3000;

    setTimeout(() => {
        toastMessage.style.opacity = '0';  // Fade out the toast message
    }, delay);
};
