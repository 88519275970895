export const showNavScroll = () => {
    // Dynamically add CSS for the vdx-hide-nav class
    const style = document.createElement('style');
    style.type = 'text/css';
    style.innerHTML = `
        .vdx-hide-nav {
            transform: translateY(-100%);
            opacity: 0;
        }
    `;
    document.head.appendChild(style);

    // Handle elements with vdx-shownav-scrolldown
    const scrollElements = document.querySelectorAll('[vdx-shownav-scrolldown]');
    scrollElements.forEach(nav => {
        // Set initial state by adding the class that hides the element
        nav.style.visibility = 'hidden';
        nav.classList.add('vdx-hide-nav');

        const scrollValue = nav.getAttribute('vdx-shownav-scrolldown');
        const scrollUnit = scrollValue.match(/[a-zA-Z%]+/)[0];
        const scrollAmount = parseFloat(scrollValue);

        const getScrollThreshold = (value) => {
            if (scrollUnit === 'px') {
                return value;
            } else if (scrollUnit === 'rem') {
                return value * parseFloat(getComputedStyle(document.documentElement).fontSize);
            } else if (scrollUnit === '%') {
                return (value / 100) * document.documentElement.scrollHeight;
            } else if (scrollUnit === 'vh') {
                return (value / 100) * window.innerHeight;
            } else {
                return value; // Default to pixels if unit is not recognized
            }
        };

        const scrollThreshold = getScrollThreshold(scrollAmount);

        window.addEventListener('scroll', function () {
            const currentScrollY = window.scrollY;

            if (currentScrollY >= scrollThreshold) {
                nav.classList.remove('vdx-hide-nav');
                nav.style.removeProperty('visibility');
            } else {
                nav.classList.add('vdx-hide-nav');
            }
        });
    });

    // Handle elements with vdx-shownav-scrollbackup
    const scrollBackupElements = document.querySelectorAll('[vdx-shownav-scrollbackup]');
    scrollBackupElements.forEach(nav => {
        const scrollBackupValue = nav.getAttribute('vdx-shownav-scrollbackup');
        const scrollUnit = scrollBackupValue.match(/[a-zA-Z%]+/)[0];
        const scrollBackupAmount = parseFloat(scrollBackupValue);

        const getScrollThreshold = (value) => {
            if (scrollUnit === 'px') {
                return value;
            } else if (scrollUnit === 'rem') {
                return value * parseFloat(getComputedStyle(document.documentElement).fontSize);
            } else if (scrollUnit === '%') {
                return (value / 100) * document.documentElement.scrollHeight;
            } else if (scrollUnit === 'vh') {
                return (value / 100) * window.innerHeight;
            } else {
                return value; // Default to pixels if unit is not recognized
            }
        };

        const scrollBackupThreshold = getScrollThreshold(scrollBackupAmount);

        // On page load, if vdx-shownav-scrollbackup exists, make the nav visible
        nav.classList.remove('vdx-hide-nav');

        let lastScrollY = window.scrollY;

        window.addEventListener('scroll', function () {
            const currentScrollY = window.scrollY;

            // If scrolling down past the scrollBackupThreshold, hide the nav
            if (currentScrollY > lastScrollY && currentScrollY > scrollBackupThreshold) {
                nav.classList.add('vdx-hide-nav');
            }

            // If scrolling back up, show it again
            if (currentScrollY < lastScrollY && currentScrollY > scrollBackupThreshold) {
                nav.classList.remove('vdx-hide-nav');
            }

            // Update last scroll position
            lastScrollY = currentScrollY;
        });
    });

    // Handle vdx-shownav-nav and vdx-shownav-triggertop attributes
    const scrollNavShowElements = document.querySelectorAll('[vdx-shownav-nav], [vdx-shownav-triggertop]');

    scrollNavShowElements.forEach(element => {
        const navscrollNavbar = document.querySelector('[vdx-shownav-nav]');
        const navscrollTrigger = document.querySelector('[vdx-shownav-triggertop]');

        if (navscrollNavbar && navscrollTrigger) {
            // Set initial state by adding the class that hides the element
            navscrollNavbar.classList.add('vdx-hide-nav');
            navscrollNavbar.style.visibility = 'hidden';

            const scrollValue = navscrollTrigger.getAttribute('vdx-shownav-triggertop');
            const scrollUnitMatch = scrollValue.match(/[a-zA-Z%]+/);
            const scrollUnit = scrollUnitMatch ? scrollUnitMatch[0] : 'px'; // Default to 'px' if no unit is found
            const scrollAmount = parseFloat(scrollValue);

            const getScrollThreshold = (value) => {
                if (scrollUnit === 'px') {
                    return value;
                } else if (scrollUnit === 'rem') {
                    return value * parseFloat(getComputedStyle(document.documentElement).fontSize);
                } else if (scrollUnit === '%') {
                    return (value / 100) * document.documentElement.scrollHeight;
                } else if (scrollUnit === 'vh') {
                    return (value / 100) * window.innerHeight;
                } else {
                    return value; // Default to pixels if unit is not recognized
                }
            };

            const triggerThreshold = getScrollThreshold(scrollAmount);

            window.addEventListener('scroll', function () {
                const scrollPosition = window.scrollY;
                const viewportHeight = window.innerHeight;

                const navscrollTriggerTop = navscrollTrigger.getBoundingClientRect().top + scrollPosition;
                const triggerPoint = navscrollTriggerTop - viewportHeight * (scrollAmount / 100);

                if (scrollPosition >= triggerPoint) {
                    navscrollNavbar.classList.remove('vdx-hide-nav');
                    navscrollNavbar.style.removeProperty('visibility');
                } else {
                    navscrollNavbar.classList.add('vdx-hide-nav');
                }
            });
        }
    });
};
