export const formatDate = () => {
    try {
        // Get today's date
        const today = new Date();

        // Helper function to apply the custom format based on Microsoft format codes
        const formatDate = (format) => {
            const formatMap = {
                'd': today.getDate(), // Day of the month (1-31)
                'dd': String(today.getDate()).padStart(2, '0'), // Day of the month (01-31)
                'ddd': today.toLocaleString('default', { weekday: 'short' }), // Abbreviated day of the week (Mon, Tue)
                'dddd': today.toLocaleString('default', { weekday: 'long' }), // Full day of the week (Monday, Tuesday)
                'M': today.getMonth() + 1, // Month number (1-12)
                'MM': String(today.getMonth() + 1).padStart(2, '0'), // Month number (01-12)
                'MMM': today.toLocaleString('default', { month: 'short' }), // Abbreviated month name (Jan, Feb)
                'MMMM': today.toLocaleString('default', { month: 'long' }), // Full month name (January, February)
                'y': today.getFullYear(), // Full year (2024)
                'yy': String(today.getFullYear()).slice(2), // Year, last two digits (24)
                'yyyy': today.getFullYear() // Full year (2024)
            };

            // Replace each format code in the string with the corresponding value
            return format.replace(/d{1,4}|M{1,4}|y{2,4}/g, (match) => formatMap[match] || match);
        };

        // Map of attributes to content generators
        const contentGenerators = {
            'vdx-date-day': (format, lang) => {
                let dayName = today.toLocaleString(lang || 'default', { weekday: 'long' });
                switch (format) {
                    case 'short':
                        return dayName.slice(0, 3);  // "Mon"
                    case 'narrow':
                        return dayName.slice(0, 2);  // "Mo"
                    default:
                        return dayName;  // Full day name
                }
            },
            'vdx-date-year': (format) => {
                return format === '2-digit' ? String(today.getFullYear()).slice(2) : today.getFullYear();
            },
            'vdx-date-month': (format, lang) => {
                return today.toLocaleString(lang || 'default', { month: format || 'long' });
            },
            'vdx-date-date': (format) => {
                if (format === 'suffix') {
                    const dateNumeric = today.getDate();
                    const suffix = ['th', 'st', 'nd', 'rd'][((dateNumeric % 100) - 20) % 10] || 'th';
                    return `${dateNumeric}${suffix}`;
                }
                return format === '2-digit' ? String(today.getDate()).padStart(2, '0') : today.getDate();
            },
            'vdx-date-currentdate': (format) => {
                return format ? formatDate(format) : formatDate('MMMM d, yyyy');
            }
        };

        // Select all elements with any of the vdx-date-* attributes
        const elements = document.querySelectorAll('[vdx-date-day], [vdx-date-year], [vdx-date-month], [vdx-date-date], [vdx-date-currentdate]');

        // Iterate over each element and apply the appropriate content
        elements.forEach(element => {
            let dataType, format, lang;
            if (element.hasAttribute('vdx-date-day')) {
                dataType = 'vdx-date-day';
                format = element.getAttribute('vdx-date-day');
            } else if (element.hasAttribute('vdx-date-year')) {
                dataType = 'vdx-date-year';
                format = element.getAttribute('vdx-date-year');
            } else if (element.hasAttribute('vdx-date-month')) {
                dataType = 'vdx-date-month';
                format = element.getAttribute('vdx-date-month');
            } else if (element.hasAttribute('vdx-date-date')) {
                dataType = 'vdx-date-date';
                format = element.getAttribute('vdx-date-date');
            } else if (element.hasAttribute('vdx-date-currentdate')) {
                dataType = 'vdx-date-currentdate';
                format = element.getAttribute('vdx-date-currentdate');
            }

            lang = element.getAttribute('vdx-date-lang');
            const generateContent = contentGenerators[dataType];

            if (generateContent) {
                try {
                    const content = generateContent(format, lang);
                    requestAnimationFrame(() => {
                        element.textContent = content;
                        });
                } catch (error) {
                    console.error(`Error generating content for ${dataType}.`, error);
                }
            } else {
                console.error(`Unknown attribute type: "${dataType}".`);
            }
        });
    } catch (error) {
        console.error("An unexpected error occurred while processing date elements.", error);
    }
};
