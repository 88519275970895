export const countUp = () => {
    const elements = document.querySelectorAll('[vdx-count-up]');

    // Helper function to animate the count
    const startCount = (element) => {
        const targetValue = +element.textContent.trim();
        const duration = +element.getAttribute('vdx-count-up') || 2000;
        let startTime = null;

        const animateCount = (timestamp) => {
            if (!startTime) startTime = timestamp;
            const progress = Math.min((timestamp - startTime) / duration, 1);
            element.textContent = Math.floor(progress * targetValue);

            if (progress < 1) {
                requestAnimationFrame(animateCount);
            } else {
                element.textContent = targetValue; // Ensure final value is exact
            }
        };

        requestAnimationFrame(animateCount); // Start the animation
    };

    // Set up the IntersectionObserver
    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                startCount(entry.target); // Start count when element comes into view
                observer.unobserve(entry.target); // Stop observing once counted
            }
        });
    });

    // Observe each element with the [vdx-count-up] attribute
    elements.forEach(observer.observe.bind(observer));
};
