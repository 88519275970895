export const handleChildHoverState = () => {
    const hoverStateElements = document.querySelectorAll("[vdx-child-hover]");
    hoverStateElements.forEach(element => {
        const hoverClass = element.getAttribute("vdx-child-hover") || 'vdx--hover';
        element.addEventListener('mouseenter', () => {
            element.querySelectorAll('*').forEach(child => {
                child.classList.add(hoverClass);
            });
        });
        element.addEventListener('mouseleave', () => {
            element.querySelectorAll('*').forEach(child => {
                child.classList.remove(hoverClass);
            });
        });
    });
};
handleChildHoverState();
// You can add any additional logic to handle these elements here